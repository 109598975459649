
import React, { useEffect, useState } from 'react'
import "../../../pages/v2/Profile/ProfileV2.css"
import { useNavigate } from 'react-router-dom';


export default function ProfileCreateV2({ url }) {
    const [loaderPercent, setLoaderPercent] = useState(0);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (loaderPercent < 90) {
                setLoaderPercent(loaderPercent + 1);
            } else {
                setLoaderPercent(99);
                window.location.href = url;
            }
        }, 600) // in milliseconds
        return () => clearInterval(intervalId);
    });

    const percentageMapper = {
        0: "Configuring Database",
        10: "Configuring Database",
        20: "Configuring Panel",
        30: "Mapping Domains",
        40: "Loading and Integrating the data",
        50: "Loading and Integrating the data",
        60: "Configuring Settings",
        70: "Configuring Settings",
        80: "Configuring Dashboard",
        90: "Configuring Dashboard",
    }

    return (
        <div className="card client-loading-bar-container  p-2">
            <div className="row">
                <div className="col-12">
                    <div className="loading-bar-heading">
                        <h2 className="mb-3">All right Let’s configure your panel</h2>
                        <span >Please wait while we are configuring your panel</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="loading-bar mt-3 mb-4">
                        <progress className="mb-3 " value={loaderPercent} max="100"> </progress><br />
                        <span className="loading-paragraph"> {percentageMapper[Math.floor(loaderPercent / 10) * 10]} </span>
                        <br />
                        <span className="percentage-value mb-1">Please wait ({loaderPercent}%)</span>
                    </div>
                </div>
            </div>
        </div>
    );
}