import React, { useEffect, useState } from "react";
import Logo from "../../assets/BlinkExam.svg";
import ComputerInr from "../../assets/computer_inr_v2.svg";
import ComputerUsd from "../../assets/computer_usd_v2.svg";
import iplocation from "iplocation";
import { fetchIPData } from "../../utils";

export default function LeftSideContainer() {
  const [country, setCountry] = useState("");
  const [computerImage, setComputerImage] = useState(ComputerInr); // Set default image

  // Function to fetch IP and country information
  const getIPData = async () => {
    try {
      const ip = await fetchIPData();
      const location = await iplocation(ip);
      const userCountry = location.country.name.toLowerCase();
  
      // console.log("IP Location:", userCountry);
      setCountry(userCountry);
      
      // Set image based on country
      if (userCountry !== "india") {
        setComputerImage(ComputerUsd);
      }
    } catch (error) {
      console.error("Error fetching IP or country information:", error);
    }
  };

  useEffect(() => {
    getIPData();
  }, []);

  return (
    <div className="col-xl-6 col-md-6 p-0" style={{ margin: "auto" }}>
      <div className="static-data pt-4">
        <div className="text-container">
          <div className="container logo-container">
            <img src={Logo} className="img-fluid" alt="BlinkExam Logo" />
          </div>
          <div className="container">
            <div className="trial-info">
              <p className="heading-text pt-3 mb-1">Start your free trial</p>
              <p className="sub-heading">
                No credit card required, no software to install
              </p>
              <ul>
                <li className="paragraph mb-4">With your 7-day trial, you get:</li>
                <li className="paragraph mb-4">6 Types Of Questions</li>
                <li className="paragraph mb-4">
                  2 Types of Tests: Normal test & Quiz
                </li>
                <li className="paragraph mb-4">
                  Get 20 free tests for a limited period of time.
                </li>
                <li className="paragraph mb-4">
                  Online training and live onboarding webinar
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="logo-container-computer ms-3">
              <img className="img-fluid" src={computerImage} alt="Computer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
