import { useCallback } from 'react';

const useGTM = () => {
  const pushGTMEvent = useCallback((eventName, eventData) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...eventData
      });
    }
  }, []);

  return { pushGTMEvent };
};

export default useGTM;