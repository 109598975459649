import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineCopyAll } from "react-icons/md";
import "../WebUrl/WebUrlV2.css";
import axios from "axios";
import { toast } from "react-toastify";
import ProfileCreateV2 from "../Profile/ProfileCreateV2";
import CaptchaService from "../../../services/captcha.service";
import { CONFIG } from "../../../app-config";
import { GTM_EVENTS, UTM_API_ENDPOINT } from "../../../constant";
import useGTM from "../../../custom-hooks/gtm-hook";

export default function WebUrlV2({ nextStep, formData, recaptchatoken, utm }) {
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [manage, setManage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const handleUrlChange = (value) => {
    setUrl(value.replaceAll(/[^a-z]/g, ""));
    setCopied(false);
    setManage(false);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    if (manage) {
      setTimeout(() => {
        setManage(false);
      }, 3000);
    }
  }, [copied, manage])

  const { pushGTMEvent } = useGTM();

  const sendUTMData = async (utmData) => {
    try {
      const response = await axios.post(`${CONFIG.API_PATH_V2}${UTM_API_ENDPOINT.UTM_ENDPOINT}`, utmData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("UTM API Response:", response.data);
    } catch (error) {
      toast.error("Failed to send UTM data");
      console.error("UTM API Error:", error);
      if (error.response) {
        console.log("UTM API Error Response:", error.response);
      }
    }
  };

  const register = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const captchaService = new CaptchaService();
      const token = await captchaService.generateCaptchaToken();

      if (!token) {
        setLoading(false);
        toast.error("Invalid Captcha");
        return;
      }

      const urlData = {
        service: "authService",
        method: "addClient",
        data: {
          mobile: formData.phone,
          firstname: formData.firstname.trim(),
          lastname: formData.lastname.trim(),
          email: formData.email.trim(),
          psw: formData.password.trim(),
          industrytype: formData.industry,
          url: url,
          otp: formData.otp,
          isMobileOtp: CONFIG.IS_PHONE_OTP_ENABLED ? '1' : '0',
          recaptchatoken: token,
        },
      };

      const response = await axios.post(CONFIG.API_PATH, `request=${JSON.stringify(urlData)}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const utmData = {
        utm_code: utm,
        client_url: url,
      };
      const dataArr = response.data;
      if (dataArr.code === "100") {

        

        pushGTMEvent(GTM_EVENTS.CLIENT_REGISTERED, {
          data: {
            mobile: formData.phone,
            firstname: formData.firstname.trim(),
            lastname: formData.lastname.trim(),
            email: formData.email.trim(),
            industrytype: formData.industry,
            url: url,
          }
        });

        await sendUTMData(utmData);
        setLoading(true)
        setLoadingSuccess(true);
      } else {
        setLoading(false);
        toast.error(`Error while creating URL: ${response?.data?.error?.msg || "Something went wrong!!"}`);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create URL");
      console.error("API Error:", error);
    }
  };

  return loadingSuccess ? (
    <ProfileCreateV2 color="#283E9C" url={`https://${CONFIG.PREFIX_NAME}-${url}.${CONFIG.DOMAIN_NAME}`} />
  ) : (
    <div className="card client-url-container mt-3 p-2">
      <div className="row">
        <div className="col-12 ">
          <div className="url-heading">
            <h2>Almost done! create your URL</h2>
          </div>
        </div>
      </div>
      <form onSubmit={register}>
        <div className="row">
          <div className="col-md-12 col-lg-12 mt-3">
            <div className="url-input">
              <div className="input-container">
                <input
                  type="text"
                  value={url}
                  id="txt"
                  maxLength={10}
                  className="form-control user-url p-2 pl-3 mb-3 mt-2"
                  placeholder="Create your business URL"
                  name="url"
                  autoComplete="off"
                  onChange={(e) => handleUrlChange(e.target.value)}
                  onPaste={(e) => handleUrlChange(e.target.value)}
                />
              </div>
              <div className="url-notes">
                <small style={{ color: "#283E9C", fontWeight: "500" }}>
                  Please copy your portal Link
                </small>
                <b className="candidate" style={{ alignSelf: "center", justifyContent: "inherit" }}>
                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#283E9C", textAlign: "center" }}>
                    Candidate URL:&nbsp;
                  </span>
                  <span style={{ color: "#283E9C" }}>
                    {url ? url : "(url)"}.blinkexam.com &nbsp;
                    <CopyToClipboard
                      style={{ color: "#283E9C" }}
                      text={`${url}.${CONFIG.DOMAIN_NAME}`}
                      onCopy={() => setCopied(true)}
                    >
                      <MdOutlineCopyAll size={20} />
                    </CopyToClipboard>
                    {copied && <span style={{ fontSize: "10px", color: "#3BB900" }}>Copied!</span>}
                  </span>
                </b>

                <b style={{ alignSelf: "center" }}>
                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#283E9C", textAlign: "center" }}>
                    Admin URL:{" "}
                  </span>
                  <span style={{ color: "#283E9C" }}>
                    {CONFIG.PREFIX_NAME}-{url ? url : "(url)"}.{CONFIG.DOMAIN_NAME}&nbsp;
                    <CopyToClipboard
                      style={{ color: "#283E9C" }}
                      text={`${CONFIG.PREFIX_NAME}-${url}.${CONFIG.DOMAIN_NAME}`}
                      onCopy={() => setManage(true)}
                    >
                      <MdOutlineCopyAll size={20} />
                    </CopyToClipboard>
                    {manage && <span style={{ fontSize: "10px", color: "#3BB900" }}>&nbsp; Copied!</span>}
                  </span>
                </b>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12 col-lg-12 ">
            <div className="sign-btn">
              <button type="submit" className="btn Sign-up col-12 text-dark" disabled={loading || !url}>
                {loading ? (
                  <div disabled>
                    Create Panel
                    <span className="spinner-border spinner-border-sm" style={{ marginLeft: "5%", color: "#fffff" }} role="status" aria-hidden="true"></span>
                  </div>
                ) : (
                  "Create Panel"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
