import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import { CONFIG } from "./app-config";
import { BrowserRouter as Router } from "react-router-dom";
import iplocation from "iplocation";
import LeftSideContainer from "./pages/v2/LeftSideContainer";
const root = ReactDOM.createRoot(document.getElementById("root"));


if (CONFIG.GA_ID) {
  ReactGA.initialize(CONFIG.GA_ID);
}


root.render(
  <React.StrictMode>
    <Router>
      <div className="container-fluid left-side-container p-0">
        <div className="row m-0 p-0">
          <LeftSideContainer/>
          <div className="col-xl-6 col-md-6 col-xs-12 p-0">
            <App />
          </div>
        </div>
      </div>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
