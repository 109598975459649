export const StorageKeys = {
    CAPTCHA: "captchaToken"
}

export const GA_CATEGORY = {
    USER_INTERACTIONS: "USER_INTERACTIONS"
}

export const GA_ACTIONS = {
    CLICKED: "CLICKED",
    INPUT_FOCUSED: "INPUT_FOCUSED",
    INPUT_BLURED: "INPUT_BLURED",
}

export const OTP_SERVICE = {
    MASSAGE: 'text-massage',
    WHATSAPP: 'whatsapp',
};
export const UTM_API_ENDPOINT = {
    UTM_ENDPOINT: 'utmMap'
}
export const WHATSAPP_API_ENDPOINT = {
    WHATSAPP_ENDPOINT: 'whatssapOtpSend'
}


export const GTM_EVENTS = {
    CLIENT_REGISTERED: "CLIENT_REGISTERED"
}

export const SelectOrganization = {
    ORG_NAME: 'Organization / Individual',
    STUDENT: 'Student',
};

