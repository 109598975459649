import React, { useCallback, useEffect, useState } from "react";
import Logo from "./assets/latesLogo.png";
import SignUp from "./pages/SignUp";
import OtpVerification from "./pages/OtpVerification";
import WebUrl from "./pages/WebUrl";
import OtpVerificationV2 from "./pages/v2/Otp/OtpVerificationV2";
import WebUrlV2 from "./pages/v2/WebUrl/WebUrlV2";
import "./pages/Form.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaptchaService from "./services/captcha.service";
import "./App.css";
import Support from "./components/Support";
import useGoogleAnalytics from "./custom-hooks/ga-hook";
import { GA_CATEGORY } from "./constant";
import { CONFIG } from "./app-config";
import SignUpV2 from "./pages/v2/SignUp/SignUpV2";
import iplocation from "iplocation";
import { fetchIPData } from "./utils";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import ThankYou from "./pages/v2/ThankYou";
import useGTM from "./custom-hooks/gtm-hook";


export default function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [childOtp, setChildtOtp] = useState({});
  const [country, setCountry] = useState("");
  const [ip, setIP] = useState("");

  const executeRecaptcha = useCallback(async () => {
    const captchaService = new CaptchaService();
    await captchaService.generateCaptchaToken();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParamValue = searchParams.get("utm") || "";

  useGoogleAnalytics(GA_CATEGORY.USER_INTERACTIONS);

  useEffect(() => {
    if (CONFIG.GTM_ID) {
      // Create and append the script tag for Google Tag Manager
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${CONFIG.GTM_ID}');`;
      document.head.appendChild(script);

      // Create and append the noscript iframe for Google Tag Manager
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${CONFIG.GTM_ID}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      // Append the <iframe> to the <noscript>
      noscript.appendChild(iframe);

      document.body.appendChild(noscript);

      // Cleanup function to remove the script and noscript when the component unmounts
      return () => {
        const existingScript = document.querySelector('script[src*="gtm.js"]');
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
        const existingNoscript = document.querySelector('noscript');
        if (existingNoscript) {
          document.body.removeChild(existingNoscript);
        }
      };
    }
  }, []);



  useEffect(() => {
    executeRecaptcha();
    getIPData();
  }, [executeRecaptcha]);

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleOtp = (otp) => {
    setChildtOtp(otp);
    setFormData((prevFormData) => ({
      ...prevFormData,
      otp: otp,
    }));
  };
  const getIPData = async () => {
    try {

      // Fetch the user's IP address
      const ip = await fetchIPData();
      setIP(ip);

      // Fetch the geographical information about the IP address
      const location = await iplocation(ip);
      // console.log("IP Location:", location); // Debug log

      setCountry(location.country.code.toLowerCase());
    } catch (error) {
      console.error("Error fetching IP or country information:", error);
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const UI_VERSION_V1 = {
    SignUp: SignUp,
    OtpVerification: OtpVerification,
    WebUrl: WebUrl,
  };
  const UI_VERSION_V2 = {
    SignUp: SignUpV2,
    OtpVerification: OtpVerificationV2,
    WebUrl: WebUrlV2,
  };

  const STEP_TO_COMPONENT_MAPPER = {
    1: "SignUp",
    2: "OtpVerification",
    3: "WebUrl",
  };

  const UI_VERSION = CONFIG.ENABLE_V2_UI ? UI_VERSION_V2 : UI_VERSION_V1;
  // console.log(CONFIG.ENABLE_V2_UI);
  // console.log(CONFIG.ENABLE_V2_UI ? "V2" : "V1")
  const Component = UI_VERSION[STEP_TO_COMPONENT_MAPPER[currentStep]];


  return (
    <div className="app-login-new-container">
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <div className="row" style={{ height: "100vh" }}>
              <div className="card col-12 card-data-container">
                <div className="d-flex justify-content-center">
                  {/* Optional Logo or other content */}
                </div>
                <Component
                  nextStep={nextStep}
                  formData={formData}
                  handleFormData={handleFormData}
                  setFormData={setFormData}
                  handleOtp={handleOtp}
                  country={country}
                  childOtp={childOtp}
                  utm={queryParamValue}
                />
              </div>
            </div>
          }
        />
        {/* <Route path="/thank-you" element={<ThankYou />} /> */}
        {/* Optionally, add a route for 404 Not Found */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Support />
    </div>
  );
}
