export const jsonToFormData = (json) => {
    const formData = new FormData();
    appendFormData(formData, "", json);
    return formData;
}

const appendFormData = (formData, key, value) => {
    if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
            appendFormData(formData, key + "[" + i + "]", value[i]);
        }
    } else if (typeof value === "object" && value !== null) {
        for (const k in value) {
            if (value.hasOwnProperty(k)) {
                const newKey = key === "" ? k : key + "[" + k + "]";
                appendFormData(formData, newKey, value[k]);
            }
        }
    } else {
        formData.append(key, value);
    }
}

export const fetchIPData = async () => {
    try {
      const res = await fetch("https://api.ipify.org/?format=json");
      const data = await res.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      throw error;
    }
  };
  
  